export default function SmartTag(text) {
  let response = text;
  if (text) {
    if (text === "comingsoon") {
      response = "Coming soon";
    } else if (text === "outnow") {
      response = "Out now";
    } else if (text === "earlyaccess") {
      response = "Early access";
    } else if (text === "demo") {
      response = "Demo";
    } else if (text === "update") {
      response = "Update";
    } else if (text === "announcement") {
      response = "Announcement";
    } else if (text === "blog") {
      response = "Blog";
    } else if (text === "press") {
      response = "Press release";
    } else if (text === "permanent") {
      response = "Full-time";
    } else if (text === "parttime") {
      response = "Part-time";
    } else if (text === "fixedterm") {
      response = "FTC";
    } else if (text === "temp") {
      response = "Temp";
    } else if (text === "contract") {
      response = "Contract";
    } else if (text === "esrb") {
      response = "USA/Canada";
    } else if (text === "pegi") {
      response = "Europe";
    } else if (text === "usk") {
      response = "Germany";
    } else if (text === "acb") {
      response = "Australia";
    }

    return response;
  }
  return null;
}
