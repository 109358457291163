import React from "react";
import PropTypes from "prop-types";
import ShareLinks from "../Global/ShareLinks";
import Moment from "react-moment";
import Link from "../Global/Link";

const Title = (props) => {
  const {
    title,
    description,
    button,
    link,
    background,
    text,
    accent,
    supporting,
    date,
    share,
  } = props;

  return (
    <div
      className={`row mb-3 mb-md-5 justify-content-between align-items-center brand-background-${
        background ? background : "transparent"
      }`}
    >
      <div className="col-12 col-md-8 col-lg-6">
        <div
          className={`pt-4 pb-2 ps-4 brand-border-start brand-border-${
            accent ? accent : "pink"
          } brand-text-${text ? text : "black"}`}
        >
          {title && <h2 className="brand-font">{title}</h2>}
          {description && <p>{description}</p>}
          {date && (
            <p className="small">
              Posted on <Moment format="Do MMMM, YYYY" date={date} />
            </p>
          )}
        </div>
      </div>
      <div
        className={`col-12 col-md-auto text-end d-md-block${
          share && " align-self-start"
        }`}
      >
        {share && (
          <ShareLinks
            meta={share.meta}
            openGraph={share.openGraph}
            facebook
            twitter
            linkedin
            email
          />
        )}
        {supporting && (
          <div>
            <span className="brand-text-pink fs-2 brand-font">
              {supporting} roles
            </span>
          </div>
        )}
        {button && (
          <Link to={link} button icon colour={text}>
            {button}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Title;

Title.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
};

Title.defaultProps = {
  settings: null,
  text: "black",
};
